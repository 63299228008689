<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="get_modal_view_menu"
  >
    <v-card class="expande-horizontal wrap">
      <div class="expande-horizontal px-1 pr-3 py-3">
        <v-btn dark @click="fecha_modal_view_menu" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt; width: 100%;" class="fonte">
          Menu
        </span>
        <div class="expande-horizontal"></div>
        <v-btn class="elevation-3" icon @click="fecha_modal_view_menu">
          <v-icon color="red">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pt-0 pb-0">
        <v-flex class="pa-3" xs12>
          <div class="expande-horizontal column">
            <!-- <modal-send-archive /> -->
            <v-form ref="form">
              <v-flex class="px-1" xs12>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  Nome
                </span>
                <v-text-field
                  v-model="get_menu.nome"
                  dense
                  solo
                  flat
                  outlined
                  :color="$theme.primary"
                  label="ex: GOVERNANÇA E REGULAÇÃO DA INTERNET: PODER, REDES E DADOS"
                ></v-text-field>
              </v-flex>

              <v-flex class="px-1" xs12>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  Nomenclatura
                </span>
                <v-text-field
                  v-model="get_menu.nomenclatura"
                  dense
                  solo
                  flat
                  outlined
                  :color="$theme.primary"
                  label="ex: GRDDIRELE0261"
                ></v-text-field>
              </v-flex>

              <v-flex class="px-1" xs12>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  Carga Horária
                </span>
                <v-text-field
                  v-model="get_menu.carga_horaria"
                  dense
                  solo
                  flat
                  outlined
                  :color="$theme.primary"
                  type="number"
                  placeholder="ex: 3200"
                ></v-text-field>
              </v-flex>

              <v-flex class="px-1" xs12>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  Professor Responsável
                </span>
                <v-autocomplete
                  v-model="get_menu.professor"
                  :items="get_professores.docs"
                  item-text="nome"
                  dense
                  return-object
                  solo
                  flat
                  outlined
                  :color="$theme.primary"
                  placeholder="ex: Roberto"
                ></v-autocomplete>
              </v-flex>

              <v-flex class="px-1" xs12>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  Obrigatória?
                </span>
                <v-switch
                  v-model="get_menu.obrigatoria"
                  :color="$theme.primary"
                ></v-switch>
              </v-flex>
            </v-form>
          </div>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large dark @click="valida_form" tile block color="green">
          <span class="fonte"> Salvar </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
export default {
  components: { ModalSendArchive },
  computed: {
    ...mapGetters([
      "get_menu",
      "get_professores_filtros",
      "get_professores",
      "get_modal_view_menu",
      "getLoggedUser"
    ])
  },
  methods: {
    ...mapActions([
      "criar_menu",
      "atualizar_menu",
      "listar_professores",
      "fecha_modal_view_menu"
    ]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_menu._id ? this.atualizar_menu() : this.criar_menu();
      }
    }
  },
  created() {
    this.get_professores_filtros.all = true;
    this.listar_professores();
  },
  beforeDestroy() {
    this.get_professores_filtros.all = false;
  }
};
</script>
