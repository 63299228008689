var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expande-horizontal wrap pa-3 pl-1 pt-1 pb-0 pr-0 fonte"},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"dense":"","filled":"","append-inner-icon":"mdi-magnify","label":"Faça uma busca...","hide-details":"","color":_vm.$theme.primary},model:{value:(_vm.value.search),callback:function ($$v) {_vm.$set(_vm.value, "search", $$v)},expression:"value.search"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"expande-horizontal"},[_c('v-card',{staticClass:"pa-1 expande-horizontal centraliza wrap",staticStyle:{"justify-content":"space-between"},attrs:{"outlined":"","dark":"","color":"#f2f2f2"}},_vm._l(([
            'Placa',
            'Peça Principal',
            'Peça Secundária',
            'Acabamento',
            'Material'
          ]),function(productType){return _c('v-btn',{key:productType,staticClass:"fonte mx-0 px-1",class:{
            'white--text font-weight-bold': _vm.value.type == productType
          },staticStyle:{"font-size":"7pt"},attrs:{"color":_vm.value.type == productType ? _vm.$theme.primary : '',"text":_vm.value.type != productType,"x-small":"","light":""},on:{"click":function($event){return _vm.changeProductType(productType)}}},[_vm._v(_vm._s(productType))])}),1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }