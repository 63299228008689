<template>
  <div class="expande-horizontal wrap pa-3 pl-1 pt-1 pb-0 pr-0 fonte">
    <v-flex xs12>
      <v-text-field
        dense
        filled
        append-inner-icon="mdi-magnify"
        label="Faça uma busca..."
        v-model="value.search"
        hide-details
        :color="$theme.primary"
      ></v-text-field>
    </v-flex>
    <v-flex xs12>
      <div class="expande-horizontal">
        <v-card outlined dark color="#f2f2f2" style="justify-content: space-between" class="pa-1 expande-horizontal centraliza wrap">
          <v-btn
            class="fonte mx-0 px-1"
            :color="value.type == productType ? $theme.primary : ''"
            @click="changeProductType(productType)"
            :text="value.type != productType"
            :class="{
              'white--text font-weight-bold': value.type == productType
            }"
            x-small
            light
            :key="productType" 
            style="font-size: 7pt;"
            v-for="productType in [
              'Placa',
              'Peça Principal',
              'Peça Secundária',
              'Acabamento',
              'Material'
            ]">{{ productType }}</v-btn>
        </v-card>
      </div>
    </v-flex>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  methods: {
    changeProductType(productType) {
      let value = {
        ...this.value,
        type: productType
      }
      this.$emit("input", value);
      this.$forceUpdate();
    }
  }
};
</script>
