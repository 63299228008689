<template>
  <div
    :id="chave"
    :ref="chave"
    style="cursor: pointer; width: 30px; height: 30px; background: #f2f2f2; border-radius: 3px; display: flex; align-items: center; justify-content: center; margin: 3px;"
  >
    <label style="cursor: pointer;" for="input-file">
      <v-icon color="orange">mdi-camera-outline</v-icon>
    </label>
    <v-form ref="form">
      <input
        style="cursor: pointer;"
        id="input-file"
        type="file"
        accept="image/*"
        name="importxml"
        @change="upload"
      />
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["chave"],
  data() {
    return {
      viewImgDialog: false,
      link: ""
    };
  },
  computed: {
    ...mapGetters(["getLinksById", "getPercentage"])
  },
  methods: {
    ...mapActions([
      "createConfirmAction",
      "addLink",
      "removeLink",
      "setPencentageSent",
      "disableModalSendArchive",
      "enableLoading",
      "disableLoading",
      "setCurrentLinkInfo",
      "setLinksById"
    ]),
    upload(evt) {
      this.enableLoading();
      evt.stopPropagation();
      evt.preventDefault();
      var file = evt.target.files[0];

      var metadata = {
        contentType: file.type
      };

      this.setCurrentLinkInfo({ file, metadata });

      const self = this;
      this.$storage
        .ref("images")
        .child(file.name)
        .put(file, metadata)
        .then(function(snapshot) {
          snapshot.ref.getDownloadURL().then(function(url) {
            let linksById = self.getLinksById;
            linksById.push({
              url,
              chave: self.chave
            });
            self.setLinksById(linksById);
            self.$refs.form.reset();
          });
          self.$store.dispatch("disableLoading");
        })
        .catch(function(error) {
          // [START onfailure]
          self.$store.dispatch("disableLoading");
          // [END onfailure]
        });
    }
  }
};
</script>

<style>
input[type="file"] {
  display: none;
}

.input-wrapper label {
  background: linear-gradient(-45deg, #e6e8eb, #e8e8e9);
  border-radius: 5px;
  color: #fff;
}

.img {
  border-radius: 6px;
  border: 2px solid rgb(71, 73, 71);
}
</style>
